<script setup lang="ts">
    import gsap from "gsap";
    import { ScrollTrigger } from "gsap/all";

    onMounted(() => {
        gsap.registerPlugin(ScrollTrigger);
        ScrollTrigger.getAll().forEach((trigger) => {
            trigger.kill();
        })
    });

    const globalState = useGlobalState();

    globalState.value.mode = 'normal';

    const { locale } = useI18n();

    const { data: translations } = await useAsyncData('translations', async () => {
        const res = await $fetch<any>(`/api/translations?lang=${locale.value}`)
        return res
    }, { watch: [locale] })

    const isShow = ref<boolean>(true);
    const handleShowChange = (newValue: boolean) => {
        isShow.value = newValue;
    };
</script>

<template>
    <HeadTags
        :title="translations?.title || ''"
        :description="translations?.description || ''"
        :url="translations?.url || ''"
        :type="translations?.type || ''"
        :keywords="translations?.keywords || ''"
    />
    <Loader @show-change="handleShowChange"/>
    <div :class="`${globalState.mode}-mode`">
        <Menu/>
        <Sidebar/>
        <section class="sidebar-section" id="we-are">
            <Header :loader-is-active="isShow" data-sidebar-color="light"/>
            <Motto data-sidebar-color="dark"/>
            <WeAre data-sidebar-color="light"/>
        </section>
        <section class="sidebar-section" id="what-we-do">
            <WeDo data-sidebar-color="dark" id="portfolio"/>
            <Principles data-sidebar-color="dark"/>
        </section>
        <section class="sidebar-section" id="we-want">
            <WeWant data-sidebar-color="light"/>
        </section>
        <section class="sidebar-section" id="work-with-us">
            <SkillsTechnologies  data-sidebar-color="dark"/>
        </section>
        <section class="sidebar-section" id="contact-us">
            <WorkWithUs data-sidebar-color="light"/>
            <Footer data-sidebar-color="light"/>
        </section>
    </div>
</template>

<style scoped lang="scss">
    @import "scss/includes.scss";

    .mode-btn {
        position: fixed;

        z-index: 1;
    }
</style>
